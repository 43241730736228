<template>
  <span>
    <v-row
      v-if="
        (competencia_de || competencia_ate) && temas_selecionados.length > 0
      "
      class="mx-auto"
      justify="center"
    >
      <div class="mt-2 body-1 font-weight-bold">
        Há
        <span class="error--text">{{ qtdIndicadoresErros }}</span> indicador(es)
        com erros de validação
      </div>
    </v-row>

    <v-row class="mx-auto" v-if="competencia_de || competencia_ate">
      <v-col cols="12" class="mt-2">
        <v-data-table
          multi-sort
          dense
          :headers="headers"
          :items="filteredItems"
          :options="{ multiSort: false }"
          loading-text="Carregando tabela"
          :headerProps="{ sortByText: 'Ordenar Por' }"
          :footer-props="{
            itemsPerPageText: 'Itens por Página',
            itemsPerPageOptions: [10, 50, 100, 250, 500],
          }"
          no-data-text="Nenhum registro disponível"
          no-results-text="Nenhum registro correspondente encontrado"
          :mobile-breakpoint="10"
          class="row-cursor-pointer"
          @click:row="openDialogDetalhes"
        >
          <template
            v-for="headerValue in chavesIndicadores.concat(
              indicadoresLiberados
            )"
            v-slot:[getHeaderSlotName(headerValue.field)]="item"
          >
            <span :key="headerValue.field">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{ item.header.text }}
                  </span>
                </template>
                <span>
                  {{ headerValue.brief_description }}
                </span>
              </v-tooltip>
              <div
                v-if="!chavesIndicadores.includes(headerValue)"
                class="caption"
                :class="
                  qtdErrosPorIndicador[headerValue.field] > 0
                    ? 'error--text'
                    : 'success--text'
                "
              >
                {{ qtdErrosPorIndicador[headerValue.field] }} erro(s)
              </div>
            </span>
          </template>
          <template v-slot:[`item.erros`]="{ item }">
            <span :class="item.erros > 0 ? 'error--text font-weight-bold' : ''">
              {{ item.erros }}
            </span>
          </template>
          <template
            v-for="indicador in indicadoresLiberados"
            v-slot:[getItemSlotName(indicador.field)]="{ item }"
          >
            <span
              :key="indicador.field"
              :class="
                item[`erros_${indicador.field}`] > 0
                  ? 'error--text font-weight-bold'
                  : ''
              "
            >
              {{
                item[indicador.field] != undefined &&
                item[indicador.field] != null
                  ? item[indicador.field]
                  : "-" | parseNumberToFloatBrIgnoringNaN
              }}
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogDetalhes" :max-width="1360" :scrollable="true">
      <v-card v-if="dialogDetalhes">
        <v-card-title class="pt-2 headline">
          Detalhes validação
        </v-card-title>
        <v-card-text class="pt-2 pb-0">
          <v-row justify="center" class="my-2" v-if="userInfo.admin">
            <v-btn
              color="primary"
              dark
              elevation="1"
              relative
              text
              medium
              :loading="downloadingArquivoLogsAprovacoes"
              @click="downloadArquivoLogsAprovacoes()"
            >
              <v-icon left size="28px">
                mdi-text-box-outline
              </v-icon>
              Exportar Logs
            </v-btn>
          </v-row>
          <v-data-table
            :headers="dialogDetalhesHeader"
            :items="dialogDetalhesData"
            hide-default-footer
            class="elevation-1 style-table-dialog-detalhes"
            disable-pagination
            :mobile-breakpoint="10"
          >
            <template v-slot:[`item.parametro`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{ item.parametro }}
                  </span>
                </template>
                <span>
                  {{ item.brief_description }}
                </span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.valor`]="{ item }">
              <span
                v-if="
                  chavesIndicadores.find(
                    (chave) => chave.field === item.parametro.toLowerCase()
                  )
                "
              >
                {{ item.valor }}
              </span>
              <span v-else>
                {{ item.valor | parseNumberToFloatBrIgnoringNaN }}
              </span>
            </template>
            <template v-slot:[`item.erros`]="{ item }">
              <span
                v-if="
                  chavesIndicadores.find(
                    (chave) => chave.field === item.parametro.toLowerCase()
                  )
                "
              >
                -
              </span>
              <span
                v-else
                class="font-weight-medium"
                :class="item.erros > 0 ? 'error--text' : 'success--text'"
              >
                {{ item.erros | parseNumberToFloatBrIgnoringNaN }}
              </span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <span v-if="item.status">
                <v-chip
                  v-for="(validacao, tituloValidacao) in item.status"
                  :key="tituloValidacao"
                  class="mr-1"
                  small
                  :outlined="validacao.tipo == 'ERRO_CRITICO' ? false : true"
                  :color="
                    {
                      VALIDO: 'success',
                      ALERTA: 'warning',
                      ERRO_CRITICO: 'error',
                    }[validacao.tipo]
                  "
                >
                  <span class="mr-2 font-weight-black" left>
                    {{ validacao.incidencias }}
                  </span>
                  {{ tituloValidacao }}
                </v-chip>
              </span>
              <span v-else>-</span>
            </template>
            <template v-slot:[`item.aprovacao`]="{ item }">
              <span
                v-if="
                  chavesIndicadores.find(
                    (chave) => chave.field === item.parametro.toLowerCase()
                  )
                "
              >
                -
              </span>
              <template v-else>
                <v-btn
                  class="ml-n4"
                  icon
                  color="primary"
                  @click="openDialogAprovacaoIndicador(item)"
                >
                  <v-icon>mdi-open-in-new</v-icon>
                </v-btn>
                <span
                  :class="
                    {
                      NA: 'font-weight-medium',
                      Validado: 'success--text font-weight-bold',
                      'Em Análise': 'info--text font-weight-bold',
                      'Reprovado - Retificar': 'error--text font-weight-bold',
                    }[
                      statusAprovacoes[`status_${item.parametro.toLowerCase()}`]
                    ]
                  "
                >
                  {{
                    statusAprovacoes[`status_${item.parametro.toLowerCase()}`]
                  }}
                </span>
              </template>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pt-0 pr-6">
          <v-spacer />
          <v-btn
            text
            color="primary"
            class="text-h2 pa-12"
            @click="dialogDetalhes = false"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogAprovacaoIndicador"
      :max-width="1360"
      :scrollable="true"
    >
      <v-card v-if="dialogAprovacaoIndicador">
        <v-card-title class="py-2 text-center">
          <div class="headline">
            Aprovação
            {{ dialogAprovacaoIndicadorSelected.indicador | toUpperCase }} -
            {{ dialogAprovacaoIndicadorSelected.mes | addLeadingZero }}/{{
              dialogAprovacaoIndicadorSelected.ano
            }}
          </div>
          <div class="body-1">
            {{ dialogAprovacaoIndicadorSelected.brief_description }}
          </div>
        </v-card-title>
        <v-card-text class="pt-2 pb-0">
          <v-row dense justify="space-around">
            <v-col sm="12" md="3" class="py-0">
              <v-text-field
                v-model="searchDataTableAprovacaoIndicador"
                clearable
                append-icon="mdi-magnify"
                label="Filtrar municípios ou status"
                hide-details
                single-line
              />
            </v-col>
            <v-col sm="12" md="3" class="py-0 mb-1">
              <v-checkbox
                label="Mostrar apenas linhas com Erro Crítico"
                v-model="mostrarApenasLinhasComErroCritico"
                hide-details
              />
            </v-col>
          </v-row>
          <v-data-table
            :headers="dialogAprovacaoIndicadorHeader"
            :items="filteredItemsDialogAprovacaoIndicador"
            class="elevation-1 style-table-dialog-detalhes"
            :footer-props="{
              itemsPerPageOptions: [10, 15, 50, -1],
              showCurrentPage: true,
              showFirstLastPage: true,
            }"
            :mobile-breakpoint="10"
          >
            <template v-slot:[`header.aprovado`]="{ header }">
              <v-row>
                <v-checkbox
                  v-model="flagTodosAprovado"
                  :ripple="false"
                  :indeterminate="flagTodosAprovado === null"
                  hide-details
                  class="my-0"
                  :disabled="savingAprovacoes || !userIsAuthorizedByField()"
                  @change="triggerTodosAprovado"
                >
                  <template #prepend>
                    <label class="v-label">
                      {{ header.text }}
                    </label>
                  </template>
                  <template #append>
                    <v-btn
                      v-if="flagTodosAprovado !== null"
                      small
                      class="pb-1"
                      icon
                      color="info"
                      @click="setAllItemsEmAnalise()"
                    >
                      <v-icon>mdi-restart</v-icon>
                    </v-btn>
                  </template>
                </v-checkbox>
              </v-row>
            </template>
            <template v-slot:[`item.valor`]="{ item }">
              {{ item.valor | parseNumberToFloatBrIgnoringNaN }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <span
                class="font-weight-medium"
                :class="
                  {
                    VALIDO: 'success--text',
                    ALERTA: 'warning--text',
                    ERRO_CRITICO: 'error--text font-weight-black',
                  }[item.tipo_status]
                "
              >
                {{ item.status }}
              </span>
            </template>
            <template v-slot:[`item.aprovado`]="{ item }">
              <v-row>
                <v-checkbox
                  v-model="item.aprovado"
                  hide-details
                  :disabled="savingAprovacoes || !userIsAuthorizedByField()"
                  :ripple="false"
                  :indeterminate="item.aprovado === null"
                  :success="item.aprovado === true"
                  :error="item.aprovado === false"
                  class="my-0 ml-n1"
                >
                  <template v-slot:label>
                    <div
                      class="font-weight-medium"
                      :class="getCheckboxAprovacaoClass(item.aprovado)"
                      style="font-size: 12.5px"
                    >
                      {{ getCheckboxAprovacaoText(item.aprovado) }}
                    </div>
                  </template>
                </v-checkbox>
                <v-btn
                  v-if="item.aprovado !== null"
                  small
                  class="ml-1 pt-1"
                  icon
                  color="info"
                  @click="setItemEmAnalise(item)"
                >
                  <v-icon>mdi-restart</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pt-2 pr-6">
          <v-spacer />
          <v-tooltip top v-if="!userIsAuthorizedByField()">
            <template v-slot:activator="{ on, attrs }">
              <div class="pr-2" v-bind="attrs" v-on="on">
                <v-icon large color="warning">
                  mdi-alert-circle-outline
                </v-icon>
              </div>
            </template>
            <span>Você não possui autorização para validar esse indicador</span>
          </v-tooltip>
          <v-btn
            color="success"
            @click="salvaDadosAprovacoes"
            :loading="savingAprovacoes"
            :disabled="!userIsAuthorizedByField()"
          >
            Salvar
          </v-btn>
          <v-btn
            text
            color="primary"
            class="text-h2 pa-12"
            @click="dialogAprovacaoIndicador = false"
          >
            Voltar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
  import IndicadoresComerciaisV2Service from "@/services/IndicadoresComerciaisV2Service.js";

  export default {
    name: "ComercialV2VisaoDetalhadaConsolidada",
    props: {
      competencia_de: {
        type: String,
        required: true,
      },
      competencia_ate: {
        type: String,
        required: true,
      },
      temas_selecionados: {
        type: Array,
        required: true,
      },
      mostrarApenasErros: {
        type: Boolean,
        default: false,
      },
      chavesIndicadores: {
        type: Array,
        required: true,
      },
      indicadoresLiberados: {
        type: Array,
        required: true,
      },
      authorizedFields: {
        type: Array,
        required: false,
      },
      authorizedCompanies: {
        type: Array,
        required: false,
      },
    },
    data: () => ({
      headers: [],
      items: [],
      statusAprovacoes: {},
      validacoesAgregadas: {},
      dialogDetalhesSelected: {
        competencia: null,
      },
      dialogDetalhes: false,
      dialogDetalhesHeader: [
        { text: "Campo", value: "parametro", sortable: false },
        { text: "Valor", value: "valor", sortable: false },
        { text: "Erros", value: "erros", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Aprovação", value: "aprovacao", sortable: false },
      ],
      dialogDetalhesData: [],
      dialogAprovacaoIndicador: false,
      dialogAprovacaoIndicadorHeader: [
        { text: "Código IBGE", value: "com_002", sortable: true },
        { text: "Município", value: "municipio", sortable: true },
        { text: "Valor", value: "valor", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Aprovado", value: "aprovado", sortable: false },
      ],
      dialogAprovacaoIndicadorSelected: {},
      dialogAprovacaoIndicadorData: [],
      flagTodosAprovado: false,
      savingAprovacoes: false,
      searchDataTableAprovacaoIndicador: "",
      mostrarApenasLinhasComErroCritico: false,
      downloadingArquivoLogsAprovacoes: false,
    }),
    computed: {
      userInfo() {
        return this.$store.getters.getUserInfo;
      },
      filteredItems() {
        let items = JSON.parse(JSON.stringify(this.items));

        if (this.mostrarApenasErros == true) {
          items = items.filter((item) => item.erros > 0);
        }

        return items;
      },
      qtdIndicadoresErros() {
        return this.items.map((item) => item.erros).reduce((a, b) => a + b, 0);
      },
      qtdErrosPorIndicador() {
        let qtdErrosPorIndicador = {};

        this.indicadoresLiberados.forEach(
          (indicador) => (qtdErrosPorIndicador[indicador.field] = 0)
        );

        this.indicadoresLiberados.forEach((indicador) => {
          this.items.forEach((item) => {
            qtdErrosPorIndicador[indicador.field] +=
              item[`erros_${indicador.field}`];
          });
        });

        return qtdErrosPorIndicador;
      },
      filteredItemsDialogAprovacaoIndicador() {
        const search = this.searchDataTableAprovacaoIndicador || "";
        let items = this.dialogAprovacaoIndicadorData;

        if (search.trim()) {
          // Filtro de municipio e status
          items = this.dialogAprovacaoIndicadorData
            .filter((data) =>
              data.municipio.toLowerCase().includes(search.toLowerCase())
            )
            .concat(
              this.dialogAprovacaoIndicadorData.filter((data) =>
                data.com_002.toLowerCase().includes(search.toLowerCase())
              )
            )
            .concat(
              this.dialogAprovacaoIndicadorData.filter((data) =>
                data.status.toLowerCase().includes(search.toLowerCase())
              )
            );
        }

        if (this.mostrarApenasLinhasComErroCritico) {
          items = this.dialogAprovacaoIndicadorData.filter(
            (data) => data.tipo_status === "ERRO_CRITICO"
          );
        }

        return items;
      },
    },
    mounted() {
      this.getDados();
    },
    methods: {
      getDados(
        competencia_de = this.competencia_de,
        competencia_ate = this.competencia_ate,
        temas_selecionados = this.temas_selecionados
      ) {
        this.$emit("updateLoadingDados", true);

        Promise.all([
          this.getDadosConsolidados(
            competencia_de,
            competencia_ate,
            temas_selecionados
          ),
        ])
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            this.$emit("updateLoadingDados", false);
          });
      },
      getDadosConsolidados(
        competencia_de,
        competencia_ate,
        temas_selecionados
      ) {
        return IndicadoresComerciaisV2Service.getDadosConsolidados(
          competencia_de,
          competencia_ate,
          temas_selecionados.join(",")
        )
          .then((res) => {
            this.setHeaders();
            this.items = res;
          })
          .catch((err) => {
            this.$toast.error(
              "Erro ao recuperar Validações dos Indicadores.",
              "",
              {
                position: "topRight",
                timeout: 5000,
              }
            );
            throw err;
          });
      },
      getStatusAprovacoesByIndicador(
        competencia_de,
        competencia_ate,
        temas_selecionados
      ) {
        return IndicadoresComerciaisV2Service.getStatusAprovacoesByIndicador(
          competencia_de,
          competencia_ate,
          temas_selecionados.join(",")
        )
          .then((res) => {
            this.statusAprovacoes = res;
          })
          .catch((err) => {
            this.$toast.error("Erro ao recuperar Status das Aprovações.", "", {
              position: "topRight",
              timeout: 5000,
            });
            throw err;
          });
      },
      getDadosValidacoesAgregadas(
        competencia_de,
        competencia_ate,
        temas_selecionados
      ) {
        this.validacoesAgregadas = {};
        return IndicadoresComerciaisV2Service.getDadosValidacoesAgregadas(
          competencia_de,
          competencia_ate,
          temas_selecionados.join(",")
        )
          .then((res) => {
            this.validacoesAgregadas = res;
          })
          .catch((err) => {
            this.$toast.error("Erro ao recuperar Status das Aprovações.", "", {
              position: "topRight",
              timeout: 5000,
            });
            throw err;
          });
      },
      setHeaders() {
        this.headers = [{ text: "Erros", value: "erros" }];

        this.chavesIndicadores.forEach((chave) => {
          this.headers.push({
            text: chave.field.toUpperCase(),
            value: chave.field,
          });
        });

        this.indicadoresLiberados.forEach((indicador) => {
          this.headers.push({
            text: indicador.field.toUpperCase(),
            value: indicador.field,
          });
        });

        this.headers.sort();
      },
      downloadArquivoLogsAprovacoes() {
        this.downloadingArquivoLogsAprovacoes = true;

        this.$toast.info("Preparando seu arquivo para download.", "", {
          position: "topRight",
          timeout: 3000,
        });

        IndicadoresComerciaisV2Service.downloadArquivoLogsAprovacoes(
          this.dialogDetalhesSelected.competencia,
          this.dialogDetalhesSelected.competencia,
          { timeout: 60 * 60 * 1000 }
        )
          .then(async (res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              "Logs_Aprovacoes_Validacoes_Comercial_V2.xlsx"
            );
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            this.$toast.error("Erro no download do arquivo.", "", {
              position: "topRight",
              timeout: 5000,
            });
            throw err;
          })
          .finally(() => (this.downloadingArquivoLogsAprovacoes = false));
      },
      downloadArquivoValidacoes() {
        this.$emit("updateDownloadingArquivoValidacoes", true);

        this.$toast.info("Preparando seu arquivo para download.", "", {
          position: "topRight",
          timeout: 3000,
        });

        IndicadoresComerciaisV2Service.downloadArquivoValidacoesVisaoConsolidada(
          this.competencia_de,
          this.competencia_ate,
          this.temas_selecionados.join(","),
          { timeout: 60 * 60 * 1000 }
        )
          .then(async (res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            let nomeArquivo = await this.getNomeArquivoValidacoes();
            link.setAttribute("download", `${nomeArquivo}.xlsx`);
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            this.$toast.error("Erro no download do arquivo.", "", {
              position: "topRight",
              timeout: 5000,
            });
            throw err;
          })
          .finally(() =>
            this.$emit("updateDownloadingArquivoValidacoes", false)
          );
      },
      getNomeArquivoValidacoes() {
        return IndicadoresComerciaisV2Service.getNomeArquivoValidacoes()
          .then((res) => res.data.nome)
          .catch((err) => {
            this.$toast.error("Erro ao recuperar nome do arquivo.", "", {
              position: "topRight",
            });
            throw err;
          });
      },
      getHeaderSlotName(name) {
        return `header.${name}`;
      },
      getItemSlotName(indicador) {
        return `item.${indicador}`;
      },
      itemStatusContainsError(itemStatus) {
        itemStatus = itemStatus != undefined ? Object.keys(itemStatus) : [];

        if (itemStatus.length == 1 && itemStatus[0] == "OK") {
          return false;
        } else {
          return true;
        }
      },
      getCheckboxAprovacaoClass(aprovacao) {
        if (aprovacao === true) return "success--text";
        if (aprovacao === false) return "error--text";
        return "info--text";
      },
      getCheckboxAprovacaoText(aprovacao) {
        if (aprovacao === true) return "Aprovado";
        if (aprovacao === false) return "Reprovado";
        return "Em análise";
      },
      async openDialogDetalhes(items) {
        this.$emit("updateLoadingDados", true);

        this.dialogDetalhes = true;

        this.statusAprovacoes = {};
        this.dialogDetalhesData = [];

        this.dialogDetalhesSelected = {
          competencia: `${items.com_004}-${String(items.com_003).padStart(
            2,
            "0"
          )}`,
        };

        await this.getDadosValidacoesAgregadas(
          this.dialogDetalhesSelected.competencia,
          this.dialogDetalhesSelected.competencia,
          this.temas_selecionados
        );

        this.chavesIndicadores
          .concat(this.indicadoresLiberados)
          .forEach((key) => {
            this.dialogDetalhesData.push({
              parametro: key.field.toLocaleUpperCase(),
              valor: items[key.field],
              erros: items[`erros_${key.field}`],
              status: this.validacoesAgregadas[0][`validacao_${key.field}`],
              brief_description: key.brief_description,
              version_id: items.version_id,
            });
          });

        await this.getStatusAprovacoesByIndicador(
          this.dialogDetalhesSelected.competencia,
          this.dialogDetalhesSelected.competencia,
          this.temas_selecionados
        );

        this.$emit("updateLoadingDados", false);
      },
      openDialogAprovacaoIndicador(item) {
        this.dialogAprovacaoIndicadorData = [];
        this.searchDataTableAprovacaoIndicador = "";

        this.$emit("updateLoadingDados", true);

        this.dialogAprovacaoIndicadorSelected = {
          indicador: item.parametro.toLowerCase(),
          ano: this.dialogDetalhesData.filter(
            (data) => data.parametro === "COM_004"
          )[0].valor,
          mes: this.dialogDetalhesData.filter(
            (data) => data.parametro === "COM_003"
          )[0].valor,
          brief_description: item.brief_description,
          version_id: item.version_id,
        };
        const {
          indicador,
          ano,
          mes,
          version_id,
        } = this.dialogAprovacaoIndicadorSelected;

        IndicadoresComerciaisV2Service.getDadosAprovacoesIndicadorByAnoAndMes(
          indicador,
          ano,
          mes,
          version_id
        )
          .then((response) => {
            this.dialogAprovacaoIndicadorData = response;

            this.dialogAprovacaoIndicador = true;
          })
          .catch((error) => {
            this.$toast.error(
              `Erro recuperar dados de aprovação do ${indicador}`,
              "",
              {
                position: "topRight",
                timeout: 5000,
              }
            );
            throw error;
          })
          .finally(() => this.$emit("updateLoadingDados", false));
      },
      triggerTodosAprovado() {
        this.filteredItemsDialogAprovacaoIndicador
          .map((data) => data.id_validacao)
          .forEach((id_validacao) => {
            const index = this.dialogAprovacaoIndicadorData.findIndex(
              (data) => data.id_validacao === id_validacao
            );

            // Não marca como aprovado caso não esteja dentro do filtro
            if (index !== -1) {
              this.dialogAprovacaoIndicadorData[
                index
              ].aprovado = this.flagTodosAprovado;
            }
          });
      },
      setAllItemsEmAnalise() {
        this.flagTodosAprovado = null;
        this.triggerTodosAprovado();
      },
      setItemEmAnalise(item) {
        item.aprovado = null;
        this.flagTodosAprovado = false;
      },
      salvaDadosAprovacoes() {
        this.savingAprovacoes = true;

        IndicadoresComerciaisV2Service.salvarAprovacoesValidacoes({
          ...this.dialogAprovacaoIndicadorSelected,
          dadosAprovacoes: this.dialogAprovacaoIndicadorData,
        })
          .then((response) => {
            this.$toast.success(
              `Aprovações do ${this.dialogAprovacaoIndicadorSelected.indicador.toUpperCase()} salvas com sucesso.`,
              "",
              {
                position: "topRight",
                timeout: 3000,
              }
            );

            this.dialogAprovacaoIndicador = false;

            this.$emit("updateDataTable");

            this.getStatusAprovacoesByIndicador(
              this.dialogDetalhesSelected.competencia,
              this.dialogDetalhesSelected.competencia,
              this.temas_selecionados
            );
          })
          .catch((error) => {
            this.$toast.error("Erro salvar aprovações.", "", {
              position: "topRight",
              timeout: 5000,
            });
            throw error;
          })
          .finally(() => (this.savingAprovacoes = false));
      },
      userIsAuthorizedByField() {
        return (
          this.userInfo.admin ||
          (this.userInfo.validador &&
            this.authorizedFields.includes(
              this.dialogAprovacaoIndicadorSelected.indicador
            ) &&
            this.authorizedCompanies.includes(this.$store.getters.getCompanyId))
        );
      },
    },
    watch: {
      filteredItemsDialogAprovacaoIndicador() {
        this.flagTodosAprovado =
          this.filteredItemsDialogAprovacaoIndicador
            .filter((data) => data.tipo_status !== "ERRO_CRITICO")
            .every((data) => data.aprovado === true) &&
          this.filteredItemsDialogAprovacaoIndicador.filter(
            (data) => data.tipo_status !== "ERRO_CRITICO"
          ).length > 0;
      },
      dialogAprovacaoIndicador() {
        if (this.dialogAprovacaoIndicador === false)
          this.mostrarApenasLinhasComErroCritico = false;
      },
    },
  };
</script>

<style>
  .row-cursor-pointer tbody tr :hover {
    cursor: pointer;
  }

  .v-data-table table thead tr th {
    font-weight: 300;
    font-size: 14.5px;
    margin: 0px 0px;
    padding-left: 8px;
    padding-right: 24px;
  }

  .v-data-table table tbody tr td {
    font-size: 13px;
  }

  .style-table-dialog-detalhes table thead tr th {
    font-weight: 400;
    font-size: 14px;
    height: 33px;
  }

  .style-table-dialog-detalhes table tbody tr td {
    word-wrap: break-word;
    max-width: 240px;
    font-size: 12.5px;
    padding: 0px 8px !important;
    height: 35px;
  }

  .style-table-dialog-detalhes table tbody tr td .v-textarea {
    padding-top: 9px !important;
    margin-left: 9px !important;
  }

  .style-table-dialog-detalhes table tbody tr td .v-textarea textarea {
    font-size: 12.8px;
    color: rgba(0, 0, 0, 0.911);
    font-weight: 300;
  }
</style>
